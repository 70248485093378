<template>
  <div class="step">
    <p>
      유캔싸인에서 생성한 템플릿을 이용하여 외부 서비스에서 API로 간편하게 서명 요청할 수 있습니다.<br/>
      편집화면을 거치지 않고 API만으로 서명문서를 생성하고 싶을 경우, 기반이 될 템플릿 문서가 있어야 합니다. <br/>
      유캔싸인 <span class="path">관리자 페이지</span> &gt; <span class="path">템플릿</span>에서 템플릿을 생성할 수 있습니다.
    </p>
    <h3>1. 참여자 설정</h3>
    <p>
      <em>Ⓐ</em> '미리 정보 입력하기'를 눌러 참여자 정보를 미리 입력할 수 있습니다. 미리 입력할 경우 API로 서명 요청시 해당 참여자의 정보를 생략하고 요청할 수 있습니다.<br/>
      <em style="color:#27d0b0">Ⓑ</em> 해당 템플릿에 몇명의 참여자를 설정할지 결정합니다.
    </p>
    <img src="@/assets/images/devpage/signRequest01.png">
  </div>
  <div class="step">
    <h3>2. 문서 내 필드 설정</h3>
    <p>
      참여자 설정을 마친 뒤 다음단계로 넘어가면 문서 내 필드정보를 입력할 수 있는 페이지가 나옵니다.<br/>
      <em>Ⓐ</em> '추가내용 입력하기' 필드는 서명문서 생성전에 서명 요청자가 입력하는 단계로, 한번 서명문서를 생성하면 수정할 수 없는 필드입니다.<br/>
      <em style="color:#27d0b0">Ⓑ</em> 'N번째 서명 참여자' 필드는 해당 참여자의 서명순서에서 참여자가 직접 내용을 입력할 수 있는 필드입니다.
    </p>
    <img src="@/assets/images/devpage/signRequest02.png">
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i><i>
        <WarningFilled/>
      </i>'추가내용 입력하기'의 사인/도장 미리 입력하기 필드를 추가한 경우 해당 필드의 사인/도장 이미지를 등록해 주시기 바랍니다.
      </h4>
      <h5>
        사인/도장 이미지를 등록하지 않은 빈 값일 경우 API로 서명요청을 진행할 수 없습니다.
      </h5>
    </div>
    <img src="@/assets/images/devpage/signRequest03.png">
    <p>
      필드를 추가할 때, '필드이름'을 설정하시면 요청 과정에서 필드값을 입력할 때 쓰일 수 있습니다.<br/>
    </p>
    <img src="@/assets/images/devpage/signRequest04.png">
    <p>
      필드 정보를 모두 입력한 뒤에 저장하면 API를 이용한 서명문서 생성 준비가 완료됩니다.
    </p>
  </div>
  <div class="step">
    <h3>3. API로 서명 생성 요청</h3>
    <p>서명 요청 API의 요청 형태는 다음과 같습니다.</p>
    <code-highlight language="javascript">
      <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "documentName": "설정할 문서 이름(String)(Default = 템플릿 이름)",
  "folderId": "저장하고 싶은 폴더 ID(long)(Default = 기본폴더)",
  "configExpireMinute": "문서 유효기간(단위: 분)(int)(Default = 20160, 14일)",
  "documentPassword": "서명 완료시 pdf파일에 설정할 비밀번호(String)(없을 시 비밀번호 등록안함)",
  "processType": "설정할 서명 모드(String)(Default = 비대면서명)",
    PROCEDURE: 비대면 서명
    FACE_TO_FACE: 대면 서명,
  "isSequential": "순차서명/동시서명 여부. true일 경우 순차서명, false일 경우 동시서명(Boolean)(Default = true)",
  "isSendMessage": "안내 메시지 전송 여부. false일 경우 서명 요청 이메일/카카오알림톡 등이 전송되지 않고, 임베딩페이지 링크 요청을 통해서만 문서 참여 가능(Boolean)(Default = true)",
  "fields": [
    {
      "fieldId": "입력할 필드의 fieldId값(String, Number)"
      "fieldName": "입력할 필드의 fieldName값(String)",
      fieldId, fieldName 둘 중 하나만 입력
      "value": "필드에 입력할 실제 값(String or Boolean)"
    },
    {...}
  ],
  "participants": [
    {
      "authentications": [
        {
          "authType": "인증유형(String)",
            password: 2차 비밀번호
            mobile_identification: 휴대폰 본인 인증
            mobile_otp: OTP인증
          "authValuePassword": "2차인증 비밀번호(String)(authType이 password일 경우 필수값)",
          "authValueName": "휴대전화 실명인증용 이름(String)(authType이 mobile_identification일 경우 필수값)",
          "authValuePhone": "휴대전화 인증용 휴대폰 번호(String)(authType이 mobile_identification일 경우 필수값)"
        }
      ],
      "name": "참여자 이름(String) (필수값) ",
      "message": "참여자 서명 참여시 출력될 메시지(String)",
      "signingContactInfo": "참여자에게 보낼 이메일주소 또는 휴대폰 번호(String) (필수값) ",
      "signingMethodType": "참여자 서명 참여수단(String) (필수값) ",
        email: 이메일
        kakao: 카카오 알림톡
      "signingOrder": "서명순서(int)"
    },
    {...}
  ],
  "payment": {
    "productName": "상품명(String)",
    "price": "가격(long)"
  },
  "customValue": "문서에 설정할 임의 값. 웹훅 데이터 전송시에도 함께 전달됨(String)"
  "customValue1": "customValue와 동일"
  "customValue2": "customValue와 동일"
  "customValue3": "customValue와 동일"
  "customValue4": "customValue와 동일"
  "customValue5": "customValue와 동일"
}'</pre>
    </code-highlight>
    <p>각 파라미터별 설명</p>
    <h4>문서 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"documentName": 서명 문서 생성시 설정할 문서명입니다. 미입력시 템플릿 생성시 설정한 이름으로 지정됩니다. 문서명은 2글자 이상 100글자 이하여야 합니다.
"folderId": 문서를 저장할 위치를 설정합니다. 미입력시 기본폴더로 설정됩니다.
"configExpireMinute": 서명 유효기간을 설정합니다. 단위는 분, minute이며, 미입력시 20160분(14일)로 기본 설정됩니다.
"documentPassword": 서명 완료 후 PDF파일에 적용할 비밀번호를 지정합니다. 미입력시 비밀번호는 적용되지 않습니다.
"configExpireReminderDay": 참여자가 서명 만료 n일 전 서명요청 알림 이메일/알림톡을 다시 전송합니다. 단위는 일, day입니다.
"reservationDate": 문서 생성시 서명이 즉시 실행되지 않고, 지정한 날짜부터 시작하여 해당 날짜가 되면 문서 상태가
                전송대기중 -> 서명대기중으로 바뀌고, 첫번째 참여자에게 요청알림이 전송됩니다.
                해당 파라미터의 포맷은 'YYYY-MM-DD HH:mm:ss' 입니다
"processType": 서명 유형을 설정합니다. 미입력시 비대면 서명(PROCEDUER)으로 자동 설정됩니다. 아래의 두 값만 허용됩니다.
      PROCEDURE: 비대면 서명,
      FACE_TO_FACE: 대면 서명
...

신규 파라미터
"isSeuential": 문서에 순서대로 참여할지 결정합니다. true일 경우, 이전 참여자가 서명을 완료해야 참여가 가능하고, false일 경우, 모든 참여자가 동시에 서명을 진행할 수 있습니다. (순차서명/동시서명).
      미입력시 기본값은 true 입니다.
"isSendMessage": 서명 요청/완료 알림 메시지 전송 여부를 결정합니다. false로 설정시, 요청자와 참여자에게 메시지가 전송되지 않고, 서명 참여를 위해 반드시 임베딩페이지 링크 요청을 해야만 합니다.
      미입력시 기본값은 true 입니다.

</pre>
    </code-highlight>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>서명 만료 재알림일은 서명 유효기간의 일보다 높을 수 없습니다.
      </h4>
      <h5>
        <span class="path">서명 만료 재알림일(configExpireReminderDay)</span>의 단위는 day이고, <span class="path">서명 유효기간(configExpireMinute)</span>의 단위는 minute이므로,<br/>
        <span class="path">configExpireMinute > configExpireReminderDay x 24 x 60</span>식이 성립해야 합니다.
      </h5>
    </div>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
          서명 유효기간은 문서의 유효기간이 아닌 각 참여자별로 설정됩니다.
        </i>
      </h4>
      <h5>
        서명 유효기간은 서명요청이 진행된 시점, 혹은 이전 참여자가 서명을 완료한 시점에 해당 시간 + 서명 유효기간 만큼 설정됩니다.
      </h5>
    </div>
    <img src="@/assets/images/devpage/signRequest09.png"/>
    <h4>필드 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"fields": 필드에 입력할 값들의 리스트입니다. 미입력시 텍스트 필드는 수정되지 않습니다. [
  {
    "fieldId": 템플릿 생성 후 할당된 ID 값입니다. 해당 ID를 가진 필드에  value값이 입력됩니다.
    또는
    "fieldName": 템플릿 생성 중 설정한 텍스트 필드의 필드이름 값입니다. 해당 값과 같은 필드에 value값이 입력됩니다,
    "value": 해당 필드에 입력될 값입니다.
  },
  {...}
],
...</pre>
    </code-highlight>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>
        <i>
          <WarningFilled/>
        </i>
        <i>
          <WarningFilled/>
        </i>
        fieldId와 fieldName중 하나의 값을 요구하며, fieldId로 지정한 값이 1순위, fieldName으로 지정한 값이 2순위로 입력됩니다.
      </h4>
    </div>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>
        fieldId는 템플릿 상세조회 API, 또는 문서 필드 조회 API를 통해 조회하실 수 있습니다.
      </h4>
    </div>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>날짜 필드, 숫자필드는 텍스트 필드와 동일하게 적용됩니다.
      </h4>
      <h5>
        날짜필드, 숫자필드에 임의의 문자열을 입력해도 해당 문자열 그대로 입력됩니다. 이점 참고해 주시기 바랍니다.
      </h5>
    </div>
    <div class="flex">
      <code-highlight language="JavaScript">
        <pre>
"fields": [
    {
        "fieldName": "텍스트",
        "value": "가나다라"
    },
    {
        "fieldName": "날짜",
        "value": "2024년 01월 01일"
    },
    {
        "fieldName": "숫자",
        "value": "1,100,000"
    },
],</pre>
      </code-highlight>
      <img src="@/assets/images/devpage/signRequest05.png">
    </div>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i><i>
        <WarningFilled/>
      </i>추가내용 입력하기의 체크박스의 경우 기본값이 true, 참여자의 체크박스의 경우 기본값이 false 입니다.
      </h4>
      <h5>
        추가내용 입력하기의 체크박스의 경우 어떠한 값도 할당하지 않은 채로 서명요청을 실행할 경우 체크박스가 입력된 채로 서명 요청이 진행됩니다.<br/>
        입력하고 싶지 않을 경우 체크박스 필드값을 false로 설정해야 합니다.
      </h5>
    </div>
    <div class="flex">
      <code-highlight language="JavaScript">
        <pre>
"fields": [
    {
        "fieldName": "체크박스2",
        "value": false
    },
    {
        "fieldName": "체크박스4",
        "value": true
    },
],</pre>
      </code-highlight>
      <img src="@/assets/images/devpage/signRequest06.png">
    </div>
    <br/>
    <h4>참여자 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"participants": [ 템플릿에 설정한 참여자 정보에 추가정보를 입력합니다. ‘signingOrder(참여자 순서)’값과 일치하는 참여자에 해당 데이터를 덮어 씌웁니다.
  {
    "signingOrder": 템플릿에 미리 설정된 참여자 순번입니다. 예를들어 1로 설정할 경우, 본 참여자 데이터를 템플릿의 첫번째 참여자 데이터와 연결됩니다.
    "name": 참여자의 이름입니다.
    "message": 참여자가 서명하기 전 참여자에게 보여줄 메시지입니다.
    "signingMethodType": 참여자 서명에 참여할 수단입니다. 문서 설정 값 중 isSendMessage값이 false일 경우에만 'none'값이 허용됩니다
      email: 이메일
      kakao: 카카오 알림톡
      none: 미입력
    "signingContactInfo": 참여자에게 보낼 이메일주소 또는 휴대폰 번호 입니다. "signingMethodType"과 형식이 일치해야 합니다.
      signingMethodType 값이 'none'일 경우, 해당 값은 입력을 필요로 하지 않습니다.
    "authentications": [ 참여자가 문서에 접근하기 위한 추가 인증 수단입니다. 자세한 내용은 ‘참여자 추가 인증 설정’ 참고
      {
        ...
      }
    ],
  },
]
...</pre>
    </code-highlight>
    <h4>참여자 추가 인증 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"participants": [
  ...
  "authentications": [ 해당 참여자에게 추가 인증을 요구합니다. 미입력시, 최초 이메일/휴대폰 번호 입력 외의 추가 본인인증을 요구하지 않습니다.
    {
    "authType": 인증 유형입니다. 인증 유형은 총 2가지가 있습니다.
      password: 2차 비밀번호
      mobile_identification: 휴대폰 본인 인증
      mobile_otp: OTP인증
    "authValuePassword": 2차 비밀번호 입니다. "authType"이 password일 경우 필수값입니다. 아닐 경우 해당값은 무시됩니다.
    "authValueName": 휴대전화 실명인증용 이름입니다. "authType"이 mobile_identification 혹은 mobile_otp일 경우 필수값입니다. 아닐 경우 해당값은 무시됩니다.
    "authValuePhone":휴대전화 실명인증용 휴대폰번호 입니다. "authType"이 mobile_identification 혹은 mobile_otp일 경우 필수값입니다. 아닐 경우 해당값은 무시됩니다.
    }
  ],
  ...
],
...</pre>
    </code-highlight>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>mobile_identification(휴대폰 본인 인증)은 PASS(SMS)를 통한 휴대폰 명의 인증방식, mobile_otp(OTP)는 서명 화면에 출력된 번호를 참여자 휴대폰으로 전송되는 OTP 입력란에 입력하는 방식입니다.
      </h4>
      <h5>
        mobile_identification(휴대폰 본인 인증)은 비대면서명에 적합하고, OTP인증은 대면 서명에 적합한 방식입니다.
      </h5>
    </div>
    <h4>참여자 메시지 미전송 예제</h4>
    <code-highlight language="javascript">
    <pre>
...
"isSendMessage": false,
"participants": [
  {
    ...
    "signingMethodType": "none",
    ...
    ]
  },
]
...</pre>
    </code-highlight>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>참여자 메시지 미전송을 원하신 경우 아래와 같이 입력해 주시기 바랍니다.
      </h4>
      <h5>
        isSendMessage: false,<br/>
        participant.signingMethodType: 'none'
      </h5>
    </div>
    <h4>결제 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"payment": { 결제정보를 추가합니다. 해당 값이 추가되면 마지막 참여자가 서명을 완료할 시 마지막 참여자에게 결제요청 페이지가 제공됩니다.
  "productName": "상품이름 입니다. 최소 한글자 이상 입력해야 합니다.",
  "price": "결제요청할 가격입니다. 단위는 원으로, 최소 금액은 1000원 입니다."
}
...</pre>
    </code-highlight>
    <h4>임의값 설정</h4>
    <code-highlight language="javascript">
    <pre>
...
"customValue": 문서에 설정할 임의 데이터 입니다. 웹훅에 구독되어있는 상태이고 해당 문서에 웹훅 이벤트가 발생할 경우에도, 해당 값이 같이 전송됩니다.
"customValue1": "customValue와 동일"
"customValue2": "customValue와 동일"
"customValue3": "customValue와 동일"
"customValue4": "customValue와 동일"
"customValue5": "customValue와 동일"
...</pre>
    </code-highlight>
  </div>
  <!-- <div class="step">
    <h3>추가 인증수단 추가 예제</h3>
    <p>1. <em>2차 비밀번호를 추가</em>하고 싶은 경우</p>
    <code-highlight language="javascript">
        <pre>
...
"participants": [
  ...
  "authentications": [
    {
      "authType": "password",
      "authValuePassword": "abcd1234!@#$"
    }
  ],
  ...
],
...</pre>
    </code-highlight>
    <p>2. <em>휴대폰 본인인증를 추가</em>하고 싶은 경우</p>
    <code-highlight language="javascript">
        <pre>
...
"participants": [
  ...
  "authentications": [
    {
      "authType": "mobile_identification",
      "authValueName": "홍길동",
      "authValuePhone": "01012345678"
    }
  ],
  ...
],
...</pre>
    </code-highlight>
    <p>3. <em>2차 비밀번호와 휴대폰 본인인증 둘다 추가</em>하고 싶은 경우</p>
    <code-highlight language="javascript">
        <pre>
...
"participants": [
  ...
  "authentications": [
    {
      "authType": "password",
      "authValuePassword": "abcd1234!@#$"
    },
    {
      "authType": "mobile_identification",
      "authValueName": "홍길동",
      "authValuePhone": "01012345678"
    }
  ],
  ...
],
...</pre>
    </code-highlight>
  </div> -->
  <div class="step">
    <h3>유효성 검사</h3>
    <p>유캔싸인은 수신받은 데이터를 기존 템플릿에 추가 입력하거나 덮어 씌웁니다. 이후 유효성 검사를 진행합니다.</p>
    <h4>유효성 통과 조건</h4>
    <br/>
    <dl>
      <dt>1. 포인트</dt>
      <dd>서명요청 1건당 1포인트가 소비됩니다. 그러므로, 반드시 1포인트 이상 소유하거나, 자동충전기능이 활성화 된 상태여야 합니다.</dd>
    </dl>
    <dl>
      <dt>2. 문서 유효성</dt>
      <dd>
        1. 템플릿 내에 '추가내용 입력하기'로 추가한 사인/도장 필드가 존재하나, 해당 필드에 사인/도장을 입력하지 않은 상태일 경우 서명요청API를 진행할 수 없습니다. 템플릿 편집 페이지에서 '추가내용 입력하기'로 추가한 사인/도장 필드에 사인/도장을 입력해 주시거나, 빈 사인/도장 필드들을 지워주시기 바랍니다.<br/>
        2. documentName은 최소 2글자 이상이어야 합니다.
      </dd>
    </dl>
    <dl>
      <dt>3. 참여자 유효성</dt>
      <dd>
        1. signingOrder, name, signingContactInfo, signingMethodType값은 필수값입니다. 템플릿 설정시 참여자의 서명참여수단까지 입력한 상태라면, 서명요청 API 호출 시 해당 참여자는 생략이 가능합니다.<br/>
        2. signingMethodType에 따라 유효성 조건이 달라집니다.<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;2-1. signingMethodType = email일 경우 signingContactInfo 값은 반드시 이메일형식이어야 합니다.<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;2-2. signingMethodType = kakao일 경우 signingContactInfo 값은 반드시 휴대폰번호여야 합니다(하이픈 '-' 제외)
      </dd>
    </dl>
    <dl>
      <dt>4. 추가인증수단 유효성</dt>
      <dd>
        1. authentications 데이터가 있을 경우에만 유효성 검사를 합니다. 미입력시 유효성 검사를 하지 않습니다.<br/>
        2. authType에 따라 유효성 조건이 달라집니다.<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;2-1. authType = password일 경우 authValuePassword값이 필수 값입니다.<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;2-2. authType = mobile_identification 혹은 mobile_otp일 경우 authValueName, authValuePhone 값이 필수 값입니다. authValuePhone값은 반드시 휴대폰번호여야 합니다(하이픈 '-' 제외)
      </dd>
    </dl>
    <dl>
      <dt>5. 결제 정보 유효성</dt>
      <dd>
        1. payment 데이터가 있을 경우에만 유효성 검사를 합니다. 미입력시 유효성 검사를 하지 않습니다.<br/>
        2. productName값은 최소 1글자 이상 있어야 합니다.<br/>
        3. price값은 반드시 1000(원) 이상이어야 합니다.
      </dd>
    </dl>
  </div>
  <div class="highlight">
    <h4>
      <i>
        <WarningFilled/>
      </i>자동결제기능이 활성화 되어있고, 0포인트상태에서 서명요청을 진행할 경우, 포인트가 자동으로 충전됩니다.
    </h4>
    <h5>
      자동충전 기능은 <span class="path">포인트 관리</span> &gt; <span class="path">자동충전설정</span>에서 등록할 수 있습니다.<br/>
    </h5>
  </div>
  <div class="step">
    <h3>요청 결과</h3>
    <p>해당 요청을 마치고 실행에 성공할 경우, 생성된 문서의 ID값을 받습니다. 첫번째 참여자에게 서명요청 이메일 혹은 알림톡이 전송됩니다.</p>
    <div class="code-box">
      <div>
        <pre>
{
    "msg": "success",
    "result": {
        "documentId": "생성된 문서 ID(String)",
        ...(기타 문서에 관한 정보들)
    },
    "code": 0
}</pre>
      </div>
    </div>
  </div>
  <div class="step">
    <h3>샘플 코드</h3>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="심플 서명 요청">
        참여자를 제외한 나머지는 템플릿 그대로 요청합니다
        <code-highlight language="javascript">
          <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "participants": [
    {
      "name": "홍길동",
      "signingMethodType": "kakao",
      "signingContactInfo": "01012345678",
      "signingOrder": 1
    },
    {
      "name": "김유캔",
      "signingMethodType": "email",
      "signingContactInfo": "abcd@email.com",
      "signingOrder": 2
    }
  ],
}'</pre>
        </code-highlight>
      </a-tab-pane>
      <a-tab-pane key="2" tab="문서정보 커스텀">
        문서의 이름, 저장될 폴더, 서명 유효기간, 서명이 완료된 문서에 적용할 비밀번호를 설정합니다.
        <code-highlight language="javascript">
          <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "documentName": "거래 계약서",
  "folderId": 102937427356,
  "configExpireMinute": 2880,
  "documentPassword": "abcd1234!@#$",
  "configExpireReminderDay": 3,
  "reservationDate": "2024-06-14 09:00:00",
  "participants": [
    {
      "name": "홍길동",
      "signingMethodType": "kakao",
      "signingContactInfo": "01012345678",
      "signingOrder": 1
    },
    {
      "name": "김유캔",
      "signingMethodType": "email",
      "signingContactInfo": "abcd@email.com",
      "signingOrder": 2
    }
  ],
}'</pre>
        </code-highlight>
      </a-tab-pane>
      <a-tab-pane key="3" tab="텍스트 박스 입력">
        텍스트박스의 필드이름과 해당 텍스트 박스에 입력할 값을 작성합니다.
        <code-highlight language="javascript">
          <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "documentName": "거래 계약서",
  "folderId": 102937427356,
  "configExpireMinute": 2880,
  "documentPassword": "abcd1234!@#$",
  "configExpireReminderDay": 3,
  "reservationDate": "2024-06-14 09:00:00",
  "fields": [
    {
      "fieldId": 100000000000,
      "value": "동의합니다"
    },
    {
      "fieldName": "날짜",
      "value": "2000년 01월 01일"
    },
    {
      "fieldName": "계약물품",
      "value": "A4용지"
    },
    {
      "fieldName": "금액",
      "value": "15,000"
    }
  ],
  "participants": [
    {
      "name": "홍길동",
      "signingMethodType": "kakao",
      "signingContactInfo": "01012345678",
      "signingOrder": 1
    },
    {
      "name": "김유캔",
      "signingMethodType": "email",
      "signingContactInfo": "abcd@email.com",
      "signingOrder": 2
    }
  ],
}'</pre>
        </code-highlight>
      </a-tab-pane>
      <a-tab-pane key="4" tab="2차 인증수단 추가">
        참여자에게 휴대폰 본인인증 수단을 추가합니다.
        <code-highlight language="javascript">
          <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "documentName": "거래 계약서",
  "folderId": 102937427356,
  "configExpireMinute": 2880,
  "documentPassword": "abcd1234!@#$",
  "configExpireReminderDay": 3,
  "reservationDate": "2024-06-14 09:00:00",
  "fields": [
    {
      "fieldId": 100000000000,
      "value": "동의합니다"
    },
    {
      "fieldName": "날짜",
      "value": "2000년 01월 01일"
    },
    {
      "fieldName": "계약물품",
      "value": "A4용지"
    },
    {
      "fieldName": "금액",
      "value": "15,000"
    }
  ],
  "participants": [
    {
      "name": "홍길동",
      "signingMethodType": "kakao",
      "signingContactInfo": "01012345678",
      "signingOrder": 1
    },
    {
      "name": "김유캔",
      "signingMethodType": "email",
      "signingContactInfo": "abcd@email.com",
      "signingOrder": 2,
      "authentications": [
        {
          "authType": "mobile_identification",
          "authValueName": "김유캔",
          "authValuePhone": "01087654321"
        }
      ]
    }
  ],
}'</pre>
        </code-highlight>
      </a-tab-pane>
      <a-tab-pane key="5" tab="마지막 참여자에게 결제 요청하기">
        마지막 참여자에게 결제 요청을 받습니다. 서명 완료 직후에 참여자는 결제창이 나타납니다.
        <code-highlight language="javascript">
          <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--data '{
  "documentName": "거래 계약서",
  "folderId": 102937427356,
  "configExpireMinute": 2880,
  "documentPassword": "abcd1234!@#$",
  "configExpireReminderDay": 3,
  "reservationDate": "2024-06-14 09:00:00",
  "fields": [
    {
      "fieldId": 100000000000,
      "value": "동의합니다"
    },
    {
      "fieldName": "날짜",
      "value": "2000년 01월 01일"
    },
    {
      "fieldName": "계약물품",
      "value": "A4용지"
    },
    {
      "fieldName": "금액",
      "value": "15,000"
    }
  ],
  "participants": [
    {
      "name": "홍길동",
      "signingMethodType": "kakao",
      "signingContactInfo": "01012345678",
      "signingOrder": 1
    },
    {
      "name": "김유캔",
      "signingMethodType": "email",
      "signingContactInfo": "abcd@email.com",
      "signingOrder": 2,
      "authentications": [
        {
          "authType": "mobile_identification",
          "authValueName": "김유캔",
          "authValuePhone": "01087654321"
        }
      ]
    }
  ],
  "payment": {
    "productName": "A4용지",
    "price": "15000"
  }
}'</pre>
        </code-highlight>
      </a-tab-pane>
    </a-tabs>
  </div>
  <div class="step">
    <h3>테스트 모드</h3>
    <p>서명요청 기능은 고객님 계정의 포인트를 건당 1씩 차감합니다. 그러나 테스트모드로 실행할 경우 포인트 차감 없이 진행할 수 있습니다.</p>
    <p>테스트 모드로 진행을 원하실 경우 API 요청 시 헤더에 "x-ucansign-test: true" 값을 추가해 주시기 바랍니다.</p>
    <code-highlight language="javascript">
      <pre>
curl --location 'https://app.ucansign.com/openapi/templates/{템플릿ID}' \
--header 'Authorization: Bearer {Token}' \
--header 'Content-Type: application/json' \
--header 'x-ucansign-test: true' \</pre>
    </code-highlight>
    <div class="highlight">
      <h4>
        <i>
          <WarningFilled/>
        </i>테스트 모드로 생성된 문서의 계약은 그 효력을 보장하지 않습니다.
      </h4>
      <h5>
        테스트모드로 진행하게 될 경우, 모든 서명이 완료된 후 문서에 아래와 같은 워터마크가 추가됩니다.
      </h5>
      <img src="@/assets/images/devpage/signRequest08.png">
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue'
import { WarningFilled } from '@ant-design/icons-vue'

export default {
  components: {
    CodeHighlight,
    WarningFilled
  },
  setup () {
    return {
      activeKey: ref('1')
    }
  }
}
</script>

<style lang="less">
.flex {
  display: flex;
  justify-content: flex-start;

  div pre[class*="language-"] {
    margin: 0 !important;
    padding-right: 0;
    height: 100%;
    width: 324px;
  }

  img {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 1400px) {
  .flex {
    display: flex;
    flex-direction: column;

    div pre[class*="language-"] {
      width: 100%;
    }
  }
}
</style>
